import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import stocks from '../../assets/images/stocks.webp'
import arrow_right from '../../assets/images/arrow-right.webp'
import badge from '../../assets/images/badge.webp'
import product from '../../assets/images/product.webp'

const HomePage = () => {
    const [visibleDiv, setVisibleDiv] = React.useState(1);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setVisibleDiv((prev) => (prev === 1 ? 2 : 1));
        }, 3000); // Change every 2 seconds

        return () => clearInterval(interval);
    }, []);

    const fadeInOut = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1.2, delay: 0.6 } },
        exit: { opacity: 0, transition: { duration: 1.2, delay: 0.7 } }
    };
    return (
        <>
            {/* Mobile  View */}
            <section className='tw-bg-primaryBg tw-block md:tw-hidden '>
                <div className='tw-mx-5 tw-py-10'>
                    {/* Text Div */}

                    <motion.div
                        className="box homepage-animation"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 1.4,
                            delay: 0.35,
                            ease: [0, 0.71, 0.2, 1.01],
                        }}

                    >
                        <div>
                            <p className='tw-font-secondaryFonts tw-text-[15px] tw-leading-[125%]  tw-text-secondary_text'> Introducing</p>
                        </div>
                        <div className='tw-mt-3 tw-mb-4'>
                            <p className='tw-font-primaryFonts tw-text-secondary_text tw-text-[34px] 320:!tw-text-[24px] 400:tw-text-[32px] tw-leading-[125%] tw-font-bold'>
                                Play, Learn, and<br /><span className='tw-text-primary_text'> Earn </span>   with StockPe
                            </p>

                        </div>
                        <div className='tw-mb-4'>
                            <p className='tw-font-secondaryFonts tw-text-secondary_text 320:!tw-text-[12px] tw-text-desk-lg '>Create a portfolio, Compete with friends and<br />Withdraw winnings.</p>
                        </div>
                        <div className='tw-mt-6 tw-mb-10'>
                            <a href='https://stockpeglobal.app.link/stores' target='_blank' rel='noreferrer'>
                                <button className='tw-bg-primary_text mobile-button-animation  tw-w-full tw-font-secondaryFonts tw-px-5 tw-py-3 tw-rounded-md'>
                                    <div className='tw-flex tw-justify-center tw-items-center'>
                                        <span className='tw-text-desk-lg -tw-tracking-[0.3px] tw-mr-3'>Sign up For Free</span>
                                        <img className='tw-inline-block' src={arrow_right} alt='try for free' />
                                    </div>
                                </button>
                            </a>
                        </div>
                        <div className=' tw-flex tw-justify-center '>
                            <img src={stocks} alt='stocks' />
                        </div>
                        <div className='tw-mt-12 tw-mb-6 tw-flex tw-justify-center'>
                            <div className=' tw-flex tw-justify-center tw-items-center tw-relative tw-h-12 tw-w-full' >
                                <AnimatePresence >
                                    {visibleDiv === 1 && (
                                        <motion.div
                                            key="div1"
                                            initial="hidden"
                                            animate="visible"
                                            className=' tw-flex tw-justify-center'
                                            exit="exit"
                                            variants={fadeInOut}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <a href='https://www.producthunt.com/products/stockpe?utm_source=badge-featured&utm_medium=badge#stockpe' target='_blank' rel='noreferrer'>
                                                <button className='tw-bg-[#333333] tw-text-white     tw-font-secondaryFonts  tw-px-5 tw-py-2   tw-rounded-md'>
                                                    <div className='tw-flex tw-gap-4 tw-justify-between tw-items-center '>
                                                        <img width={19} height={25} className='tw-inline-block  ' src={badge} alt='badge' />
                                                        <div className='lg:tw-text-desk-lg-1 tw-text-[12px] tw-flex tw-flex-col  tw-font-medium tw-mr-3'>
                                                            <div className='tw-text-[10px] tw-flex'>Product Hunt</div>
                                                            <div className='-tw-mt-0.5'>#2 Product of the Day</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </a>
                                        </motion.div>
                                    )}
                                    {visibleDiv === 2 && (
                                        <motion.div
                                            className=' tw-flex tw-justify-center'
                                            key="div2"
                                            initial="hidden"
                                            animate="visible"
                                            exit="exit"
                                            variants={fadeInOut}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <a href='https://www.producthunt.com/products/stockpe?utm_source=badge-featured&utm_medium=badge#stockpe' target='_blank' rel='noreferrer'>
                                                <button className='tw-bg-[#333333] tw-text-white    tw-font-secondaryFonts  tw-px-5 tw-py-2   tw-rounded-md'>
                                                    <div className='tw-flex tw-gap-4 tw-justify-between tw-items-center '>
                                                        <img width={28} height={28} className='tw-inline-block  ' src={product} alt='badge' />
                                                        <div className='lg:tw-text-desk-lg-1 tw-text-[12px] tw-flex tw-flex-col  tw-font-medium tw-mr-3'>
                                                            <div className='tw-text-[10px]'>#1 Product of the week</div>
                                                            <div className='-tw-mt-0.5 tw-flex'>Fintech</div>
                                                        </div>
                                                    </div>
                                                </button>
                                            </a>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </section>


            {/* Desktop and Tab View */}
            <section className='tw-bg-primaryBg tw-hidden md:tw-block ' >
                <div className='tw-pt-[52px]  tw-pb-[40px] tw-max-w-screen-3xl 3xl:tw-mx-auto  tw-mx-20'>

                    <motion.div
                        className="box"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{
                            duration: 1.7,
                            delay: 0.35,
                            ease: [0, 0.71, 0.2, 1.01],
                        }}

                    >
                        <div className="tw-grid tw-grid-cols-2  tw-gap-4 md:tw-gap-16 xl:tw-gap-20">
                            {/* Text Col */}
                            <div className='homepage-animation'>

                                <div className='tw-my-4 lg:tw-mt-4 lg:tw-mb-6'>
                                    <h1 className='tw-font-primaryFonts tw-text-secondary_text tw-text-[1.75rem]  lg:tw-text-[2.5rem] xl:tw-text-[52px]  2xl:tw-text-[3.885rem] tw-leading-[125%] tw-font-bold'>
                                        Play, Learn, and<br /><span className='tw-text-primary_text'> Earn  </span> with StockPe
                                    </h1>

                                </div>
                                <div className='tw-my-3 lg:tw-mb-6'>
                                    <p className='tw-font-secondaryFonts tw-text-secondary_text md:tw-text-xs lg:tw-text-base xl:tw-text-desk-lg-2 -tw-tracking-[0.4px]'>Create a portfolio, Compete with friends and Withdraw winnings.</p>

                                </div>
                                <div className='tw-my-3 lg:tw-mt-4 tw-flex tw-flex-col 900:tw-flex-row lg:tw-items-center tw-gap-4'>

                                    <div className='tw-flex flex-wrap tw-items-center tw-gap-5'>
                                        <a href='https://stockpeglobal.app.link/stores' target='_blank' rel='noreferrer'>
                                            <button className='tw-bg-primary_text hover:tw-bg-btn_gradiant click-arrow   tw-font-secondaryFonts md:tw-px-4 tw-px-5 md:tw-py-2 lg:tw-py-[0.785rem]  tw-rounded-md'>
                                                <div className='tw-flex  tw-justify-between tw-items-center '>
                                                    <span className='lg:tw-text-desk-lg-1 tw-text-[12px]  tw-font-medium tw-mr-3'>Sign up for Free</span>
                                                    <img className='tw-inline-block  mv-arrow click-arrow' src={arrow_right} alt='arrow' />
                                                </div>
                                            </button>
                                        </a>
                                        <div>
                                            <div className='tw-relative tw-h-12 tw-w-[18rem]' >
                                                <AnimatePresence>
                                                    {visibleDiv === 1 && (
                                                        <motion.div
                                                            key="div1"
                                                            initial="hidden"
                                                            animate="visible"
                                                            exit="exit"
                                                            variants={fadeInOut}
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                            }}
                                                        >
                                                            <a href='https://www.producthunt.com/products/stockpe?utm_source=badge-featured&utm_medium=badge#stockpe' target='_blank' rel='noreferrer'>
                                                                <button className='tw-bg-[#333333] tw-text-white     tw-font-secondaryFonts md:tw-px-4 tw-px-5 md:tw-py-1.5   tw-rounded-md'>
                                                                    <div className='tw-flex tw-gap-4 tw-justify-between tw-items-center '>
                                                                        <img width={19} height={25} className='tw-inline-block  ' src={badge} alt='badge' />
                                                                        <div className='lg:tw-text-desk-lg-1 tw-text-[12px] tw-flex tw-flex-col  tw-font-medium tw-mr-3'>
                                                                            <div className='tw-text-[10px] tw-flex'>Product Hunt</div>
                                                                            <div className='-tw-mt-0.5'>#2 Product of the Day</div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                            </a>
                                                        </motion.div>
                                                    )}
                                                    {visibleDiv === 2 && (
                                                        <motion.div
                                                            key="div2"
                                                            initial="hidden"
                                                            animate="visible"
                                                            exit="exit"
                                                            variants={fadeInOut}
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                            }}
                                                        >
                                                            <a href='https://www.producthunt.com/products/stockpe?utm_source=badge-featured&utm_medium=badge#stockpe' target='_blank' rel='noreferrer'>
                                                                <button className='tw-bg-[#333333] tw-text-white    tw-font-secondaryFonts md:tw-px-4 tw-px-5 md:tw-py-1.5   tw-rounded-md'>
                                                                    <div className='tw-flex tw-gap-4 tw-justify-between tw-items-center '>
                                                                        <img width={28} height={28} className='tw-inline-block  ' src={product} alt='badge' />
                                                                        <div className='lg:tw-text-desk-lg-1 tw-text-[12px] tw-flex tw-flex-col  tw-font-medium tw-mr-3'>
                                                                            <div className='tw-text-[10px]'>#1 Product of the week</div>
                                                                            <div className='-tw-mt-0.5 tw-flex'>Fintech</div>
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                            </a>
                                                        </motion.div>
                                                    )}
                                                </AnimatePresence>
                                            </div>
                                        </div>


                                    </div>





                                </div>



                            </div>



                            {/* Image Col */}
                            <div className='tw-flex tw-justify-center homepage-img-animation'>
                                <img src={stocks} alt='stocks' />
                            </div>
                        </div>
                    </motion.div>

                </div>
            </section>




        </>
    )
}

export default HomePage